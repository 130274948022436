export enum TransactionType {
    CustomerCallIn = 1201,
    /// <summary>
    /// Gọi cho khách hàng
    /// </summary>
    TelesalesCallOut = 1202, //5
    /// <summary>
    /// Tạo và gửi chiến dịch marketing
    /// </summary>
    SendMarketing = 1203,
    /// <summary>
    /// Gửi email báo giá
    /// </summary>
    SendEmailQuotation = 1204,
    /// <summary>
    /// Gửi sms báo giá
    /// </summary>
    SendSMSQuotation = 1205,
    /// <summary>
    /// Gửi báo giá để duyệt
    /// </summary>
    SendQuotation = 1206,
    /// <summary>
    /// Gửi biên bản thỏa thuận để duyệt
    /// </summary>
    SendQuotationMemorandum = 1207,
    /// <summary>
    /// Gửi đơn hàng để duyệt
    /// </summary>
    SendOrder = 1208,

    SelfCreated = 3,

    TaskUpdateComplete = 1209,

    /// <summary>
    /// CheckIn Công việc
    /// </summary>
    TaskCheckIn = 1210,

    /// <summary>
    /// CheckOut công việc
    /// </summary>
    TaskCheckOut = 1211
}

