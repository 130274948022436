import {TransactionType} from "@/constants/TransactionType";

export class ActivityResult {
    id: number;
    type: number;
    types: string;
    content: string;
    config: string;
    note: string;
    createdOnDate: Date;
    modifiedOnDate: Date;
    userId: any;
    fullName: string;
    commentNo: number;
    attachmentCount: number;
    dateTransaction: Date;
    isFromTask: boolean;
    isRequiredCheckIn: boolean;
    statusCheckIn: boolean;
    isRemove: boolean;
}

export class OptionTransactionType {
    id: number;
    name: string;

    constructor(id: number) {
        this.id = id;
        switch (id) {
            case TransactionType.CustomerCallIn:
                this.name = 'Khách gọi vào';
                return;
            case TransactionType.TelesalesCallOut:
                this.name = 'Gọi ra';
                return;
            case TransactionType.SendEmailQuotation:
                this.name = 'Gửi email báo giá';
                return;
            case TransactionType.SendMarketing:
                this.name = 'Gửi chiến dịch';
                return;
            case TransactionType.SendOrder:
                this.name = 'Gửi đơn hàng';
                return;
            case TransactionType.SendSMSQuotation:
                this.name = 'Gửi SMS  báo giá';
                return;
            case TransactionType.SendQuotationMemorandum:
                this.name = 'Gửi duyệt biên bản thỏa thuận';
                return;
            case TransactionType.SendQuotation:
                this.name = 'Gửi duyệt báo giá';
                return;
            case TransactionType.SelfCreated:
                this.name = 'Tự tạo nhật ký';
                return;
            case TransactionType.TaskUpdateComplete:
                this.name = 'Hoàn thành công việc';
                return;
            case TransactionType.TaskCheckIn:
                this.name = 'Check in công việc';
                return;
            case TransactionType.TaskCheckOut:
                this.name = 'Check out công việc';
                return;
            default:
                this.name = '';
                return;
        }
    }
}