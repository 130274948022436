import {TransactionType} from "@/constants/TransactionType";

export class Activity {
    customerId: string;
    type: number;
    content: string;
    note: string;
    types: string;
    projectId: string;
    projects: any[];

    constructor() {
        this.customerId = '';
        this.type = TransactionType.SelfCreated;
        this.content = '';
        this.note = '';
        this.types = '';
    }
}