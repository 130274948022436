import axios from 'axios';
import {QueryLoadOption} from "@/models/QueryLoadOptions";
import {LoadResult} from "@/models/LoadResult";
import {Activity} from "@/models/customer/activity/Activity";
import {ActionResult} from "@/models/ActionResult";

export class ActivityService {
    url = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/transaction`;
    customerUrl = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers`;

    search(loadOptions: QueryLoadOption, customerId: string): Promise<LoadResult> {
        return axios.post(`${this.url}/${customerId}/search`, loadOptions);
    }

    insert(activity: Activity): Promise<ActionResult<number>> {
        return axios.post(`${this.url}`, activity);
    }

    searchCallLog(loadOptions: QueryLoadOption, customerId: string): Promise<LoadResult> {
        return axios.post(`${this.url}/${customerId}/call-log`, loadOptions);
    }

    delete(id: number): Promise<ActionResult<number>> {
        return axios.delete(`${this.url}/${id}`);
    }

    updateCallLog(customerId: string, callId: string, content: string, products: string): Promise<ActionResult<number>> {
        return axios.get(`${this.customerUrl}/update-call`, {params: {customerId: customerId, callId: callId, content: content, products: products}});
    }
}