




































import {Component, Prop, Vue} from "vue-property-decorator";
import {TimeLine} from "@/models/customer/activity/TimeLine";

@Component({})
export default class VxTimeline extends Vue {
    @Prop({default: () => []}) data: any[];

    remove(item: TimeLine) {
        this.$emit('remove', item);
    }
}
